import { InfoModalDetail } from "@/models/InfoModalDetail"
import { InfoModalVariant } from "./enum"
import { pluralize } from '@/utils/string'
import { RefundPolicyEntry } from "@/models/dto/Quote"

export const bookingProtectionModalDetail: InfoModalDetail = {
  label: 'Booking Protection Guarantee',
  shortLabel: 'Booking Protection',
  icon: { name: 'verified_user', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Assurance with the CharterUP Network',
      body: 'Sometimes operators need to cancel due to issues such as sick drivers or vehicle maintenance. If your selected operator is unable to fulfill the trip for any reason, we will tap our network of companies to find you a new bus fast.',
    },
    {
      subLabel: '120% Guaranteed',
      body: 'CharterUP will even cover up to 20% more than the original cost to find you a similar or better operator, or we\'ll refund you.',
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const vehicleLocationTrackingModalDetail: InfoModalDetail = {
  label: 'Vehicle Location Tracking',
  shortLabel: 'Vehicle Tracking',
  icon: { name: 'share_location', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Track Your Bus at Pickup',
      body:
        "Track your bus's location on the map as it nears the pickup point and all throughout the trip. Having on-demand access to your vehicle's whereabouts provides an extra layer of assurance and enables your group to connect for a smooth pickup experience.",
    },
    {
      subLabel: 'Share Your Ride',
      body:
        "Easily share the tracking link with the group to ensure everyone has real-time updates on the bus's status and knows exactly where to board. Even share it with colleagues, family, and friends to virtually join you on your journey.",
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const liveSupportModalDetail: InfoModalDetail = {
  label: '24/7 Live Support',
  shortLabel: '24/7 Support',
  icon: { name: 'support_agent', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Always-On Support',
      body:
        "Our customer success team serves as your travel partner, completing a pre-trip checklist to ensure you and your operator are all ready to go. We're available around-the-clock, whether you need to adjust your itinerary or sort out any unexpected bumps along the way. Your trip's success remains our top priority.",
    },
    {
      subLabel: 'Live Trip Hotline',
      body:
        'Access our Live Trip Team through a priority hotline for quick help to resolve any urgent issues on the day of the trip.',
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const qualifiedOperatorsModalDetail: InfoModalDetail = {
  label: 'Pre-Qualified Operators',
  shortLabel: 'Pre-Qualified Operators',
  icon: { name: 'bus_alert', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Operator Compliance',
      body:
        "The CharterUP partnership team conducts thorough due diligence to assess and pre-qualify all operators. This involves verifying the company's DOT status, licenses, certifications, and safety records to offer only qualified partners.",
    },
    {
      subLabel: 'Standards of Service',
      body:
        'CharterUP Partners are required to commit to our set of operator standards for vehicles, reliability of service, and code of conduct.',
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const certifiedPartnerModalDetail: InfoModalDetail = {
  label: 'Certified Partners',
  shortLabel: 'Certified Partners',
  icon: {
    name: 'verified',
    viewBox: '0 -960 960 960',
    color: 'yellow'
  },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Fair Pricing, Reliability, and Service Excellence',
      body: 'The Certified Partner stamp means that CharterUP works closely with the operators, vetting them for fair pricing, reliability, and quality of service.',
    },
    {
      subLabel: 'Live Vehicle Tracking',
      body: 'Certified Partners are required to offer live vehicle location tracking through CharterUP. Customers are able to see where their bus is at all times, enhancing the trip experience and helping coordinate timely pickups.',
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const refundPolicyModalDetail = (
  refundPolicy: RefundPolicyEntry[]
): InfoModalDetail => {
  const modalText = []
  for (const entry of refundPolicy) {
    //refundablePercent is nonNull
    const percentRefund = entry.refundablePercent * 100
    const subLabel =
      percentRefund > 0
      ? `${percentRefund}% refund`
      : 'No refund'

    let body = ''
    if (!entry.maxHours && !!entry.minHours) {
      const useDays = entry.minHours > 24
      const timeUnit = useDays
        ? 'day'
        : 'hour'
      const duration = useDays
        ? Math.floor(entry.minHours / 24)
        : entry.minHours
      body = `Cancellations ${duration} ${pluralize(
          duration,
          timeUnit.toString()
        )} or more from pickup`
    }

    if (!entry.minHours && !!entry.maxHours) {
      const useDays = entry.maxHours > 24
      const timeUnit = useDays
        ? 'day'
        : 'hour'
      const duration = useDays
        ? Math.floor(entry.maxHours / 24)
        : entry.maxHours
      body = `Cancellations fewer than ${duration}
        ${pluralize(duration, timeUnit.toString())}  from pickup`
    }

    if (!!entry.minHours && !!entry.maxHours) {
      const useMaxDays = entry.maxHours > 24
      const maxTimeUnit = useMaxDays
        ? 'day'
        : 'hour'
      const maxDuration = useMaxDays
        ? Math.floor(entry.maxHours / 24)
        : entry.maxHours
      const useMinDays = entry.minHours > 24
      const minTimeUnit = useMinDays
        ? 'day'
        : 'hour'
      const minDuration = useMinDays
        ? Math.floor(entry.minHours / 24)
        : entry.minHours

      body = `Cancellations between ${maxDuration - 1}
        ${pluralize(maxDuration, maxTimeUnit.toString())} and ${minDuration} ${pluralize(
          minDuration,
          minTimeUnit.toString()
        )} from pickup`
    }

    modalText.push({ subLabel, body })
  }

  return {
    label: 'What is our refund policy?',
    shortLabel: 'What is our refund policy?',
    icon: {
      name: 'free_cancellation',
      viewBox: '0 0 24 24',
      color: 'primary',
    },
    modalVariant: InfoModalVariant.Itemized,
    modalText,
    footerText: '*All refund percentages are based on the total amount of the booked trip.',
    showCharterUPLogo: false,
    showSupportButton: false,
  }
}

export const charterupChoiceModalDetail: InfoModalDetail = {
  label: 'CharterUP\'s Choice',
  shortLabel: 'CharterUP\'s Choice',
  icon: {
    name: 'charterup_choice',
    color: 'primary',
    viewBox: '0 0 24 24',
  },
  modalVariant: InfoModalVariant.Custom,
  showCharterUPLogo: true,
  showSupportButton: false
}
