
import { Vue, Component, Prop } from 'vue-property-decorator'
import { InfoModalDetail } from '@/models/InfoModalDetail'

@Component({})
export default class InfoModalTextItemized extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail

  get modalText(): { subLabel: string; body: string }[] {
    return this.modalDetail?.modalText || []
  }
}
