
import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import CharterUpLogo from '../CharterUpLogo.vue'
import { InfoModalVariant } from '@/utils/enum'
import InfoModalTextStandard from './InfoModalTextStandard.vue'
import InfoModalTextSimple from './InfoModalTextSimple.vue'
import InfoModalTextItemized from './InfoModalTextItemized.vue'

@Component({ components: { CharterUpLogo } })
export default class InfoModal extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail

  @Model('input', { default: false }) value!: boolean

  get icon(): string {
    return this.modalDetail?.icon?.name || ''
  }

  get iconViewBox(): string {
    return this.modalDetail?.icon?.viewBox || '0 0 24 24'
  }

  get iconColor(): string {
    return this.modalDetail?.icon?.color || ''
  }

  get label(): string {
    return this.$vuetify.breakpoint.mdAndUp
      ? this.modalDetail.label
      : this.modalDetail.shortLabel
  }

  get showFooterText(): boolean {
    return !!this.modalDetail?.footerText
  }

  get showCharterUPLogo(): boolean {
    return !!this.modalDetail?.showCharterUPLogo
  }

  get textComponent(): string {
    const variant = this.modalDetail?.modalVariant || ''
    const map = {
      [InfoModalVariant.Standard]: InfoModalTextStandard,
      [InfoModalVariant.Simple]: InfoModalTextSimple,
      [InfoModalVariant.Itemized]: InfoModalTextItemized,
    }
    return map[variant] || null
  }

  keyListener(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.close()
    }
  }

  close() {
    this.value = false
  }
}
